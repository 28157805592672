body {
  overflow-x: hidden;
}

.button.is-primary.is-outlined {
  background-color: transparent;
  border-color: #b31d23;
  color: #b31d23;
}

.button.is-primary.is-outlined.is-focused,
.button.is-primary.is-outlined.is-hovered,
.button.is-primary.is-outlined:focus,
.button.is-primary.is-outlined:hover {
  background-color: #b31d23;
  border-color: #b31d23;
  color: #fff;
}

.button.is-primary.is-focused:not(:active),
.button.is-primary:focus:not(:active) {
  box-shadow: 0 0 0 0.125em rgb(179 29 35 / 25%);
}

.navbar.is-primary {
  background-color: #b31d23;
}

.navbar-item.has-dropdown.is-active .navbar-link,
.navbar.is-primary .navbar-item.has-dropdown:focus .navbar-link,
.navbar.is-primary .navbar-item.has-dropdown:hover .navbar-link {
  background-color: #b31d23;
  color: #fff;
}

.navbar.is-primary .navbar-end .navbar-link.is-active,
.navbar.is-primary .navbar-end .navbar-link:focus,
.navbar.is-primary .navbar-end .navbar-link:hover,
.navbar.is-primary .navbar-end > a.navbar-item.is-active,
.navbar.is-primary .navbar-end > a.navbar-item:focus,
.navbar.is-primary .navbar-end > a.navbar-item:hover,
.navbar.is-primary .navbar-start .navbar-link.is-active,
.navbar.is-primary .navbar-start .navbar-link:focus,
.navbar.is-primary .navbar-start .navbar-link:hover,
.navbar.is-primary .navbar-start > a.navbar-item.is-active,
.navbar.is-primary .navbar-start > a.navbar-item:focus,
.navbar.is-primary .navbar-start > a.navbar-item:hover {
  background-color: #b31d23;
  color: #fff;
}

.navbar.is-primary .navbar-brand .navbar-link.is-active,
.navbar.is-primary .navbar-brand .navbar-link:focus,
.navbar.is-primary .navbar-brand .navbar-link:hover,
.navbar.is-primary .navbar-brand > a.navbar-item.is-active,
.navbar.is-primary .navbar-brand > a.navbar-item:focus,
.navbar.is-primary .navbar-brand > a.navbar-item:hover {
  background-color: #b31d23;
  color: #fff;
}

.station {
  margin-top: -80px;
  margin-bottom: 50px;
}

.select select {
  width: 250px;
  max-width: 100%;
  outline: 0;
}

.footer {
  background-color: #b31d23;
  padding: 2rem 1.5rem 2rem;
}

.footer p {
  color: #fff;
}

.serchsection {
  background-image: url("../assets/background.png");
  background-size: cover;
  padding: 0;
}

.store-image {
  background-size: cover;
  height: 500px;
  max-width: 100%;
  object-fit: cover;
}

.store-image-mobile {
  background-size: cover;
  /* max-width: 100%; */
  object-fit: cover;
  width: 100%;
  /* height: 300px; */
}

.store-image-wrapper {
  padding: 0;
}

/* チェックボックスボタンSTART */
.btnRED_wrap {
  display: inline-block;
  position: relative;
  margin: 5px 5px 5px 5px;
}

.btnRED_wrap input {
  opacity: 0;
  position: absolute;
  left: 0;
}

.btnRED_wrap label {
  padding: 6px 15px 6px 10px;
  border-radius: 4px;
  border: 1px solid #b31d23;
  color: #b31d23;
  display: inline-block;
  cursor: pointer;
  transition: 0.3s;
}

.btnRED_wrap label:hover,
.btnRED_wrap input:checked + label {
  background: #b31d23;
  color: #fff;
}

.btnRED_wrap input:focus + label {
  box-shadow: 0 0 4px #b31d23;
}

.btnWHITE_wrap {
  display: inline-block;
  position: relative;
  margin: 5px 5px 5px 5px;
}

.btnWHITE_wrap input {
  opacity: 0;
  position: absolute;
  left: 0;
}

.btnWHITE_wrap label {
  padding: 6px 15px 6px 10px;
  border-radius: 4px;
  border: 1px solid #222;
  color: #222;
  display: inline-block;
  cursor: pointer;
  transition: 0.3s;
}

.btnWHITE_wrap label:hover,
.btnWHITE_wrap input:checked + label {
  background: #222;
  color: #fff;
}

.btnWHITE_wrap input:focus + label {
  box-shadow: 0 0 4px #222;
}

.btnSPARKLING_wrap {
  display: inline-block;
  position: relative;
  margin: 5px 5px 5px 5px;
}

.btnSPARKLING_wrap input {
  opacity: 0;
  position: absolute;
  left: 0;
}

.btnSPARKLING_wrap label {
  padding: 6px 15px 6px 10px;
  border-radius: 4px;
  border: 1px solid #3e8ed0;
  color: #3e8ed0;
  display: inline-block;
  cursor: pointer;
  transition: 0.3s;
}

.btnSPARKLING_wrap label:hover,
.btnSPARKLING_wrap input:checked + label {
  background: #3e8ed0;
  color: #fff;
}

.btnSPARKLING_wrap input:focus + label {
  box-shadow: 0 0 4px #3e8ed0;
}

.btnROSE_wrap {
  display: inline-block;
  position: relative;
  margin: 5px 5px 5px 5px;
}

.btnROSE_wrap input {
  opacity: 0;
  position: absolute;
  left: 0;
}

.btnROSE_wrap label {
  padding: 6px 15px 6px 10px;
  border-radius: 4px;
  border: 1px solid #f14668;
  color: #f14668;
  display: inline-block;
  cursor: pointer;
  transition: 0.3s;
}

.btnROSE_wrap label:hover,
.btnROSE_wrap input:checked + label {
  background: #f14668;
  color: #fff;
}

.btnROSE_wrap input:focus + label {
  box-shadow: 0 0 4px #f14668;
}

.btnSWEET_wrap {
  display: inline-block;
  position: relative;
  margin: 5px 5px 5px 5px;
}

.btnSWEET_wrap input {
  opacity: 0;
  position: absolute;
  left: 0;
}

.btnSWEET_wrap label {
  padding: 6px 15px 6px 10px;
  border-radius: 4px;
  border: 1px solid #ffcc00;
  color: #ffcc00;
  display: inline-block;
  cursor: pointer;
  transition: 0.3s;
}

.btnSWEET_wrap label:hover,
.btnSWEET_wrap input:checked + label {
  background: #ffcc00;
  color: #fff;
}

.btnSWEET_wrap input:focus + label {
  box-shadow: 0 0 4px #ffcc00;
}

/* チェックボックスボタンEND */

input.wine-search {
  width: 300px;
}

.pagination-link.is-current {
  background-color: #b31d23;
  border-color: #b31d23;
  color: #fff;
}

.pagination-previous.is-disabled,
.pagination-previous[disabled] {
  background-color: #dbdbdb;
  border-color: #dbdbdb;
  box-shadow: none;
  color: #7a7a7a;
  opacity: 0.5;
}

.progress.is-primary:indeterminate {
  background-image: linear-gradient(to right, #b31d23 30%, #ededed 30%);
}

.select:not(.is-multiple):not(.is-loading)::after {
  border-color: #363636;
}

.nav-menu-right {
  margin-left: auto;
}

/* カードレイアウト部分をラッピングし、
Flexboxを指定"space-between"で各アイテムを均等に配置し、
最初と最後のアイテムを端に寄せます。*/
#cardlayout-wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  margin: 2em auto;
  /*max-width: 960px;*/
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  /* justify-content: space-between; */
}

/* リンクテキストの下線を非表示 */
a.card-link {
  text-decoration: none;
}

/* カードレイアウト内の画像を幅いっぱいに表示 */
#cardlayout-wrap img {
  display: block;
  max-width: 100%;
  height: 150px;
  margin: 0 auto;
  object-fit: cover;
}

.card-figure {
  margin: 0;
  padding: 0;
}

/* カードレイアウトのタイトル部分 */
.card-title {
  margin: 0.6em auto;
  color: #333;
  font-size: 1 em;
  display: table;
  text-align: left;
}

/* カードレイアウトの説明文部分 */
.card-text-tax {
  margin: 0;
  padding: 1em;
  color: #818181;
}

/* カードレイアウトを1カラムで配置 */
.card-list-small {
  margin: 0.5em auto;
  padding: 0.5rem;
  /* padding: 1.25rem; */
  width: 96%;
  background-color: #fff;
  color: #4a4a4a;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0 0 1px rgb(10 10 10 / 2%);
}

/* 画面幅768px以上の場合カードレイアウトを2カラムで配置 */
/* @media screen and (min-width: 768px) { */
.card-list-middle {
  margin: 1% 1% 1% 1%;
  width: calc(96% / 2); /* 96%幅を2で割るという指定 */
  /* margin: 0.5em auto; */
  /* padding: 1.25rem; */
  padding: 0.5rem;

  /* width: 96%; */
  background-color: #fff;
  color: #4a4a4a;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0 0 1px rgb(10 10 10 / 2%);
}
/* } */

/* 画面幅992px以上の場合カードレイアウトを3カラムで配置 */
/* @media screen and (min-width: 992px) { */
.card-list-large {
  margin: 1% 1% 1% 1%;
  width: calc(94% / 3); /*96%幅を3で割るという指定*/
  /* margin: 0.5em auto; */
  /* padding: 1.25rem; */
  padding: 0.5rem;

  /* width: 96%; */
  background-color: #fff;
  color: #4a4a4a;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0 0 1px rgb(10 10 10 / 2%);
}

/* 画面幅1200px以上の場合カードレイアウトを4カラムで配置 */
/* @media screen and (min-width: 1200px) { */
.card-list-exlarge {
  margin: 1% 1% 1% 1%;
  width: calc(92% / 4);
  /* 96%幅を3で割るという指定  */
  /* margin: 0.5em auto; */
  /* padding: 1.25rem; */
  padding: 0.5rem;

  /* width: 96%; */
  background-color: #fff;
  color: #4a4a4a;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0 0 1px rgb(10 10 10 / 2%);
}

/* 画面幅1500px以上の場合カードレイアウトを5カラムで配置 */
/* @media screen and (min-width: 1500px) {
    .card-list {
        margin: 1% 1% 1% 1%;
        width: calc(90% / 5); 96%幅を3で割るという指定 
} */

/* カードレイアウトを1カラムで配置 */
.card-list {
  margin: 0.5em auto;
  padding: 0.5rem;
  /* width: 96%; */
  width: 150px;
  background-color: #fff;
  color: #4a4a4a;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0 0 1px rgb(10 10 10 / 2%);
}

.scroll-img {
  object-fit: contain;
  height: 150px;
}

.scroll-table {
  display: block;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}

/* アプリーチSTART  */
.appreach {
  text-align: left;
  padding: 10px;
  border: 1px solid #7c7c7c;
  overflow: hidden;
}

.appreach:after {
  content: "";
  display: block;
  clear: both;
}

.appreach p {
  margin: 0;
}

.appreach a:after {
  display: none;
}

.appreach__icon {
  float: left;
  border-radius: 10%;
  overflow: hidden;
  margin: 0 3% 0 0 !important;
  width: 25% !important;
  height: auto !important;
  max-width: 120px !important;
}

.appreach__detail {
  display: inline-block;
  font-size: 20px;
  line-height: 1.5;
  width: 72%;
  max-width: 72%;
}

.appreach__detail:after {
  content: "";
  display: block;
  clear: both;
}

.appreach__name {
  font-size: 16px;
  line-height: 1.5em !important;
  max-height: 3em;
  overflow: hidden;
}

.appreach__info {
  font-size: 12px !important;
}

.appreach__developper,
.appreach__price {
  margin-right: 0.5em;
}

.appreach__posted a {
  margin-left: 0.5em;
}

.appreach__links {
  float: left;
  height: 40px;
  margin-top: 8px;
  white-space: nowrap;
}

.appreach__aslink img {
  margin-right: 10px;
  height: 40px;
  width: 135px;
}

.appreach__gplink img {
  height: 40px;
  width: 134.5px;
}

.appreach__star {
  position: relative;
  font-size: 14px !important;
  height: 1.5em;
  width: 5em;
}

.appreach__star__base {
  position: absolute;
  color: #737373;
}

.appreach__star__evaluate {
  position: absolute;
  color: #ffc107;
  overflow: hidden;
  white-space: nowrap;
}

/* アプリーチEND  */

.mylocation {
  position: absolute;
  margin-top: -230px;
  /* margin-top: 800px; */

  /* top: 55%; */
  /* right: 12px; */
  right: 12px;
}

.mylocation a:hover {
  opacity: 0.8;
}

.wine-section {
  margin-top: 15px;
}

.section-top {
  margin: 10px 5px 5px 10px;
}
